<script>
import Paper from '@/components/layout/WizardOverviewPaper';
import EzButton from '@/components/ui/Button/EzButton';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzLoader from '@/components/ui/Loader/EzLoader';
import flash from '@/components/ui/FlashMessage';
import {
  distributorPostAccountSetup,
  distributorPutConnections,
  distributorPutSyncItems,
} from '@/api/endpoints/xero';
import { DISTRIBUTOR_STEP_1, DISTRIBUTOR_STEP_2, DISTRIBUTOR_STEP_3 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since
 */

export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    VProductEntityInfo,
    VVenueEntityInfo,
    EzLoader,
  },
  data() {
    return {
      isLoaderShown: false,
      overviewProducts: [],
      overviewVenues: [],
      syncItems: [],
    };
  },
  computed: {
    ...mapState('xero', [
      'products',
      'venues',
      'contacts',
      'draft',
      'selectedProducts',
      'connections',
    ]),
    ...mapGetters('xero', [
      'combinedExpenseBillLabel',
      'isBillCombined',
    ]),
    expenseTaxName() {
      return this.draft?.expensesTax?.name;
    },
  },
  methods: {
    ...mapMutations('xero', ['CLEAR_STATE']),
    productsItems() {
      Object.keys(this.selectedProducts).forEach((item) => {
        if (this.selectedProducts[item]) {
          this.overviewProducts.push(this.products.data.find(el => el.code === item));
        } else {
          this.syncItems.push(item);
        }
      });
    },
    outletsItems() {
      Object.keys(this.connections).forEach((key) => {
        if (this.connections[key]) {
          this.overviewVenues.push({
            outlet: this.venues.data.find(venue => venue.entity.id === +key),
            xeroContact: this.contacts.data.find(
              contact => this.connections[key] === contact.xeroId,
            ),
          });
        }
      });
    },
    editProducts() {
      this.$emit('stepBack', DISTRIBUTOR_STEP_2);
    },
    editAccount() {
      this.$emit('stepBack', DISTRIBUTOR_STEP_1);
    },
    editOutlets() {
      this.$emit('stepBack', DISTRIBUTOR_STEP_3);
    },
    async onNextStep() {
      this.isLoaderShown = true;
      const account = {
        accountCode: this.draft?.account?.code,
        deliveryFeeAccountCode: this.draft?.deliveryFeeAccount?.code,
        inventoryAccountCode: this.draft?.inventoryAccount?.code,
        cogsAccountCode: this.draft?.cogsAccount?.code,
        taxType: this.draft?.tax?.taxType,
        ...(this.draft?.expensesTax && { expensesTaxType: this.draft?.expensesTax?.taxType }),
        expensesAccountCode: {},
        expenseBillCreateDay: this.draft?.expenseBillCreateDay,
        combinedExpenseBill: this.draft?.combinedExpenseBill,
        nonStockCreditAccountCode: this.draft?.creditNote?.code,
        orderDiscountAccountCode: this.draft?.orderDiscount?.code,
      };
      this.draft.bills.forEach((bill) => {
        account.expensesAccountCode[bill.type] = bill.selected?.code;
      });

      const connections = this.overviewVenues.map(item => ({
        contactId: item.xeroContact.xeroId,
        venueId: item.outlet.entity.id,
      }));

      try {
        const promises = [];
        promises.push(distributorPostAccountSetup(account));
        if (this.syncItems.length) promises.push(distributorPutSyncItems(this.syncItems));
        if (connections.length) promises.push(distributorPutConnections(connections));
        await Promise.all(promises);
        await this.$router.push({
          name: 'distributor-integrations',
          params: {
            flash: {
              title: this.$t('xero.wizzard.overview.flashMessage.success.title'),
              message: this.$t('xero.wizzard.overview.flashMessage.success.message'),
              type: 'success',
            },
          },
        });
        this.CLEAR_STATE();
      } catch (e) {
        flash.error({
          title: this.$t('xero.wizzard.overview.flashMessage.error.title'),
        });
      } finally {
        this.isLoaderShown = false;
      }
    },
  },
  mounted() {
    this.enableNextStep();
    this.productsItems();
    this.outletsItems();
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoaderShown">{{ $t('xero.wizzard.overview.loaderMessage') }}</ez-loader>
    <paper>
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>{{ $t('xero.wizzard.overview.accountPaper.title') }}</template>
      <template #actions>
        <ez-button type="link" @click="editAccount">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-if="draft.account" class="accounts">
        <div class="accounts__info">
          <span>{{ $t('xero.wizzard.overview.accountPaper.labelAccount') }}</span>
        </div>
        <div class="accounts__value">{{ draft.account.name }}</div>
      </div>
      <div v-if="draft.tax" class="accounts">
        <div class="accounts__info">
          <span>{{ $t('xero.wizzard.overview.accountPaper.labelTax') }}</span>
        </div>
        <div class="accounts__value">{{ draft.tax.name }}</div>
      </div>
      <div v-if="draft.deliveryFeeAccount" class="accounts">
        <div class="accounts__info">
          <span>Delivery Fee Account</span>
        </div>
        <div class="accounts__value">{{ draft.deliveryFeeAccount.name }}</div>
      </div>
      <div v-if="draft.orderDiscount" class="accounts">
        <div class="accounts__info">
          <span>Order Discount Account</span>
        </div>
        <div class="accounts__value">{{ draft.orderDiscount.name }}</div>
      </div>
    </paper>
    <paper v-if="draft.creditNote">
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>Credit Notes</template>
      <template #actions>
        <ez-button type="link" @click="editAccount">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-if="draft.creditNote" class="accounts">
        <div class="accounts__info">
          <span>Non-Stock Credit Account</span>
        </div>
        <div class="accounts__value">{{ draft.creditNote.name }}</div>
      </div>
    </paper>
    <paper v-if="draft.inventoryAccount || draft.cogsAccount">
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>Inventory Accounting</template>
      <template #actions>
        <ez-button type="link" @click="editAccount">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <div v-if="draft.inventoryAccount" class="accounts">
          <div class="accounts__info">
            <span>Inventory Account</span>
          </div>
          <div class="accounts__value">{{ draft.inventoryAccount.name }}</div>
        </div>
        <div v-if="draft.cogsAccount" class="accounts">
          <div class="accounts__info">
            <span>COGS Account</span>
          </div>
          <div class="accounts__value">{{ draft.cogsAccount.name }}</div>
        </div>
      </template>
    </paper>
    <paper v-if="draft.bills.length || draft.expensesTax">
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>Bills Accounts</template>
      <template #actions>
        <ez-button type="link" @click="editAccount">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-for="bill in draft.bills" :key="bill.name" class="accounts" >
        <div class="accounts__info">
          <span>{{ bill.name }} Expenses</span>
        </div>
        <div class="accounts__value">{{ bill.selected.name }}</div>
      </div>

      <div v-if="expenseTaxName" class="accounts">
        <div class="accounts__info">
          <span>{{ $t('xero.wizzard.overview.accountPaper.labelTax') }}</span>
        </div>
        <div class="accounts__value">{{ expenseTaxName }}</div>
      </div>
    </paper>

    <paper v-if="draft.combinedExpenseBill">
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>Generate Bill to Pay</template>
        <template #actions>
          <ez-button type="link" @click="editAccount">
            <font-awesome-icon icon="pen"/>
            <span>Edit</span>
        </ez-button>
      </template>

      <div class="accounts">
        <div class="accounts__info">Type</div>
        <div class="accounts__value">{{ combinedExpenseBillLabel }}</div>
      </div>
      <div v-if="isBillCombined" class="accounts">
        <div class="accounts__info">Day of the Month</div>
        <div class="accounts__value">{{ draft.expenseBillCreateDay }}</div>
      </div>
    </paper>

    <paper v-if="overviewProducts.length">
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="">
      </template>
      <template #title>{{ $t('xero.wizzard.overview.productsPaper.title') }}</template>
      <template #actions>
        <ez-button type="link" @click="editProducts">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="overview-items"
           v-for="product in overviewProducts"
           :key="product.id">
        <div class="overview-items--info">
          <v-product-entity-info
            class="overview-items--entity"
            :product="product.product ? product.product : product" />
        </div>
        <div class="price">
          <span>{{ product.price ? product.price : '' | price }}</span>
        </div>
      </div>
    </paper>
    <paper v-if="overviewVenues.length">
      <template #title>
        {{ $t('xero.wizzard.overview.outletsPaper.title') | uppercase}}
      </template>
      <template #actions>
        <ez-button type="link" @click="editOutlets">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="overview-items"
           v-for="item in overviewVenues"
           :key="item.outlet.entity.id">
        <div class="overview-items--info">
          <v-venue-entity-info class="overview-items--entity" :venue="item.outlet.entity" />
        </div>
        <div class="contact">
          <span>{{ item.xeroContact.name }}</span>
        </div>
      </div>
    </paper>
  </div>
</template>
<style lang="scss" scoped>
:deep() .loader {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overview-items {
  @extend %flex-space;
  width: 100%;

  :not(:last-child) {
    padding-bottom: 8px;
  }

  &--info {
    max-width: 270px;

    :deep() .entity-info__text{
      white-space: nowrap;
    }
  }

  &--entity {
    width: 100%;
  }

  .contact {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: $label-color;
      @include font-size(14px, 16px);
    }
  }
  .price {
    span {
      font-weight: 600;
      @include font-size(14px, 16px);
    }
  }
}
.accounts {
  + .accounts {
    margin-top: 12px;
  }
  &__info {
    color: $label-color;
    @include font-size(12px, 16px);
    font-weight: 600;
  }
  &__value {
    @include font-size(14px, 22px);
    color: $input-color;
  }
}
.products-img {
  border-radius: 50%;
}
</style>
