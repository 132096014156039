<script>
/**
 * ProductSync
 * @version 1.0.0
 * @since
 */

import { mapMutations, mapState } from 'vuex';
import EzAlert from '@/components/ui/Alert';
import VMergeProducts from '@/components/v3/patterns/VMergeProducts';
import { distributorGetXeroProducts } from '@/api/endpoints/xero';
import { wizardListenerMixin } from '@/mixins/wizard';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzAlert,
    VMergeProducts,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapState('xero', ['selectedProducts']),
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapMutations('xero', ['UPDATE_SELECTED_PRODUCTS', 'SET_PRODUCTS']),

    onNextStep() {
      this.$emit('stepCompleted');
    },

    onPreviousStep() {
      this.$emit('stepBack');
    },

    setDefaultSelectedProducts(products) {
      const defaultProducts = {};
      products.forEach((product) => {
        defaultProducts[product.code] = false;
      });
      if (Object.keys(this.selectedProducts).length === 0) {
        this.UPDATE_SELECTED_PRODUCTS(defaultProducts);
      }
    },
  },
  async mounted() {
    const { data } = await distributorGetXeroProducts();
    this.products = data.data;
    this.SET_PRODUCTS({ data: this.products });

    this.setDefaultSelectedProducts(this.products);
    this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <ez-alert variant="disclaimer">
      <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
      <span>{{ $t('xero.wizzard.step2.alertContent', { platform: platformName }) }}</span>
    </ez-alert>
    <v-merge-products :products="products" />
  </div>
</template>
<style lang="scss" scoped></style>
