<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  distributorAuthorize,
  distributorGetXeroAccountTypes,
  distributorGetXeroTaxes,
} from '@/api/endpoints/xero';
import { wizardListenerMixin } from '@/mixins/wizard';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import VXeroBillTypeInput from '@/components/v3/patterns/VXeroBillTypeInput';

/**
 * AccountSetup
 * @version 1.0.0
 * @since 1.0.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    VXeroBillTypeInput,
    EzLoader,
    EzDropdown,
  },
  data() {
    return {
      loading: false,
      accountsCOGS: [],
      accountsRevenue: [],
      creditNotes: [],
      accountsExpenses: [],
      accountsInventory: [],
      taxes: [],
      taxesExpenses: [],
      expensesTypes: [],
      bills: [],
      orderDiscounts: [],
      expensesSelected: {
        check_in: '',
      },
    };
  },
  computed: {
    ...mapState('xero', ['draft', 'isAuthorized']),
    ...mapGetters('entities/users', ['isDistributorXeroConnected']),
    accountCode() {
      return this.draft?.account?.code;
    },
    deliveryFeeAccountCode() {
      return this.draft?.deliveryFeeAccount?.code;
    },
    creditNoteCode() {
      return this.draft?.creditNote?.code;
    },
    inventoryAccountCode() {
      return this.draft?.inventoryAccount?.code;
    },
    cogsAccountCode() {
      return this.draft?.cogsAccount?.code;
    },
    tax() {
      return this.draft?.tax?.taxType;
    },
    checkIn() {
      return this.draft?.check_in?.code;
    },
    expensesTax() {
      return this.draft?.expensesTax?.taxType;
    },
    combinedExpenseBill() {
      return this.draft?.combinedExpenseBill;
    },
    expenseBillCreateDay() {
      return this.draft?.expenseBillCreateDay;
    },
    isBothSelected() {
      return (this.draft?.account && this.draft?.tax);
    },
    orderDiscountAccountCode() {
      return this.draft?.orderDiscount?.code;
    },
  },
  watch: {
    isBothSelected() {
      this.enableNextStep();
    },
  },
  methods: {
    ...mapActions('reports', ['fetchExpenseTypes']),
    changeBill(type, name, item) {
      this.expensesSelected[type] = item.code;
      this.UPDATE_DRAFT({ [type]: item });
      this.bills.push({ selected: item, name, type });
    },
    onChangeUpdateDraft(object) {
      this.UPDATE_DRAFT(object);
    },
    ...mapMutations('xero', [
      'UPDATE_DRAFT',
      'AUTHORIZE',
    ]),

    onNextStep() {
      this.UPDATE_DRAFT({ bills: this.bills });
      this.$emit('stepCompleted');
    },
  },
  async created() {
    const params = window.location.search;
    try {
      if (!this.isAuthorized) {
        await distributorAuthorize(params);
        this.AUTHORIZE();
      }
      if (this.isDistributorXeroConnected) {
        await this.$router.push({ name: 'distributor-xero-account' });
      }
    } catch (e) {
      await this.$router.push({
        name: 'distributor-integrations',
        params: {
          flash: {
            title: 'There was an error.',
            message: 'An error occurred when authorizing Xero connection. Please try again later.',
            type: 'fail',
          },
        },
      });
    }

    try {
      this.loading = true;

      const [
        { data: accounts },
        { data: taxes },
        { data: taxesExpenses },
        { data: expensesTypes },
      ] = await Promise.all([
        distributorGetXeroAccountTypes(),
        distributorGetXeroTaxes(),
        distributorGetXeroTaxes({ type: 'expense' }),
        this.fetchExpenseTypes(),
      ]);

      this.accountsCOGS = accounts.data.cogs;
      this.accountsRevenue = accounts.data.revenue;
      this.accountsExpenses = accounts.data.expense;
      this.accountsInventory = accounts.data.inventory;
      this.creditNotes = accounts.data.creditNotes;
      this.orderDiscounts = accounts.data.orderDiscount;

      this.taxes = taxes.data;
      this.taxesExpenses = taxesExpenses.data;
      this.expensesTypes = expensesTypes.data;

      this.loading = false;
    } catch (e) {
      this.loading = false;
      flash.error({
        title: 'Something went wrong',
      });
    }

    if (this.isBothSelected) this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Invoices</h4>
    </div>
    <ez-dropdown
      name="accountCode"
      label="Invoices Account*"
      id-key="code"
      class="account-dropdown"
      :data="accountsRevenue"
      :selected="accountCode"
      :placeholder="`Select ${$t('xero.name')} Account`"
      @change="onChangeUpdateDraft({ account: $event })"
      is-full-width
    />
    <ez-dropdown
      name="taxType"
      label="Tax Rate*"
      placeholder="Select Tax Rate"
      id-key="taxType"
      class="mt-12"
      :data="taxes"
      :selected="tax"
      @change="onChangeUpdateDraft({ tax: $event })"
      is-full-width
    />
    <ez-dropdown
      name="deliveryFeeAccountCode"
      label="Delivery Fee Account"
      placeholder="Select Delivery Fee Account"
      id-key="code"
      class="mt-12"
      :data="accountsRevenue"
      :selected="deliveryFeeAccountCode"
      @change="onChangeUpdateDraft({ deliveryFeeAccount: $event })"
      is-full-width
    />
    <ez-dropdown
      name="orderDiscountAccountCode"
      label="Order Discount Account"
      placeholder="Select Order Discount Account"
      id-key="code"
      class="mt-12"
      :data="orderDiscounts"
      :selected="orderDiscountAccountCode"
      @change="onChangeUpdateDraft({ orderDiscount: $event })"
      is-full-width
    />
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Credit Notes</h4>
    </div>
    <ez-dropdown
        name="nonStockCreditAccountCode"
        label="Non-Stock Credit Account"
        placeholder="Select Non-Stock Credit Account"
        id-key="code"
        :data="creditNotes"
        :selected="creditNoteCode"
        @change="onChangeUpdateDraft({ creditNote: $event })"
        is-full-width
    />
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Inventory Accounting</h4>
    </div>
    <ez-dropdown
      name="inventoryAccountCode"
      label="Inventory Account"
      id-key="code"
      :data="accountsInventory"
      :selected="inventoryAccountCode"
      :placeholder="`Select ${$t('xero.name')} Account`"
      @change="onChangeUpdateDraft({ inventoryAccount: $event })"
      is-full-width
    />
    <ez-dropdown
      name="cogsAccountCode"
      label="COGS Account"
      id-key="code"
      class="mt-12"
      :data="accountsCOGS"
      :selected="cogsAccountCode"
      :placeholder="`Select ${$t('xero.name')} Account`"
      @change="onChangeUpdateDraft({ cogsAccount: $event })"
      is-full-width
    />
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Bills</h4>
    </div>
    <ez-dropdown
      name="AccountCheckInExpenses"
      label="Team Activity Expenses"
      id-key="code"
      :data="accountsExpenses"
      :selected="checkIn"
      :placeholder="`Select ${$t('xero.name')} Account`"
      @change="changeBill('check_in', 'Account Check-In', $event)"
      is-full-width
    />
    <ez-dropdown
      v-for="type in expensesTypes"
      id-key="code"
      class="mt-12"
      :key="type.key"
      :name="`${type.key}Expenses`"
      :label="`${type.name} Expenses`"
      :data="accountsExpenses"
      :selected="draft[type.key] ? draft[type.key].code : ''"
      :placeholder="`Select ${$t('xero.name')} Account`"
      @change="changeBill(type.key, type.name, $event)"
      is-full-width
    />
    <ez-dropdown
      name="taxRates"
      label="Tax Rate"
      placeholder="Select Tax Rate"
      id-key="taxType"
      class="mt-12"
      :data="taxesExpenses"
      :selected="expensesTax"
      is-full-width
      @change="onChangeUpdateDraft({ expensesTax: $event })"
    />
    <hr />

    <v-xero-bill-type-input
      form-key=""
      :selected-type="combinedExpenseBill"
      :selected-day="expenseBillCreateDay"
      @changeType="onChangeUpdateDraft({ combinedExpenseBill: $event })"
      @changeDay="onChangeUpdateDraft({ expenseBillCreateDay: $event.value })"
    />

    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
:deep() .loader {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
