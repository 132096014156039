<script>
/**
 * VenueSync
 * @version 1.0.0
 * @since
 */

import { mapMutations, mapState, mapGetters } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';
import { distributorGetConnectedVenues, distributorGetContacts } from '@/api/endpoints/xero';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzTable from '@/components/ui/Table/EzTable';
import EzAlert from '@/components/ui/Alert';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzLoadMore,
    EzSpinner,
    EzAlert,
    EzTable,
    VVenueEntityInfo,
    VSelectSearch,
  },
  data() {
    return {
      contactsData: [],
      connectedVenues: [],
      meta: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('xero', ['connections', 'contacts']),
    hasConnections() {
      return Object.keys(this.connections).length > 0;
    },
    isContactsEmpty() {
      return Object.keys(this.contacts).length === 0;
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_XERO_VENUES,
        LOADING_KEY.DISTRIBUTOR_FETCH_XERO_CONTACTS,
      ]);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  watch: {
    hasConnections() {
      this.enableNextStep();
    },
  },
  methods: {
    ...mapMutations('xero', ['UPDATE_VENUE_SYNC', 'SET_VENUES', 'SET_CONTACTS']),
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    xeroSelected(contact, venue) {
      this.UPDATE_VENUE_SYNC({ [venue.id]: contact.xeroId });
      if (!this.contactsData.find(item => item.xeroId === contact.xeroId)) {
        this.contactsData.push(contact);
        this.SET_CONTACTS({ data: this.contactsData });
      }
      this.enableNextStep();
    },
    async fetchVenues() {
      const query = {
        ...(this.meta.limit ? { limit: this.meta.limit } : {}),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
      };

      const { data: connectedVenues } = await distributorGetConnectedVenues({ query });
      this.connectedVenues = [...this.connectedVenues, ...connectedVenues.data];
      this.meta = connectedVenues.meta;

      this.SET_VENUES({ data: this.connectedVenues });
    },
    async fetchContacts() {
      const { data: contacts } = await distributorGetContacts();
      this.contactsData = contacts.data;

      if (this.isContactsEmpty) this.SET_CONTACTS({ data: this.contactsData });
    },
  },
  async mounted() {
    await Promise.all([this.fetchVenues(), this.fetchContacts()]);
    this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <ez-alert variant="disclaimer" class="mb-24">
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <span>{{ $t('xero.wizzard.step3.alertContent', { platform: platformName }) }}</span>
    </ez-alert>

    <ez-table
      class="sync-table"
      :disable-hover="true"
      :data="connectedVenues"
      :columns="['outletName', 'xeroContact']"
      :headers="{
        outletName: () => `${$t('global.venue')} Name`
      }"
    >
      <template #cell-outletName="{ row }">
        <v-venue-entity-info :venue="row.entity" />
      </template>
      <template #cell-xeroContact="{ row }">
        <v-select-search
          :data="contacts.data ? contacts.data : contactsData"
          :selected="connections[row.entity.id]"
          :debounceTime="700"
          search="/distributor/xero/contacts"
          value-property="xeroId"
          placeholder="Select Xero Contact"
          @selected="obj => xeroSelected(obj, row.entity)"
        />
      </template>
    </ez-table>

    <div v-if="isLoading" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoading && meta.nextId" @loadMore="fetchVenues" />
  </div>
</template>

<style lang="scss" scoped>
.sync-table tbody tr td {
  overflow: visible;
}
</style>
